import NotFound from './components/pages/NotFound/NotFound';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import './styles/main.scss';

import config from './configuration/config';
import ErrorBoundary from './components/organisms/ErrorBoundary/ErrorBoundary';
import Logger from './helpers/common/logger';

const sentryEnv = config.isDev ? (config.isLocal ? 'local' : 'staging') : 'production';
const urlPath = window.location.pathname.replace('/tempo', '') || '/';
const hasTempoDir = /^\/tempo/.test(window.location.pathname);
if (urlPath !== '/' && urlPath !== '/home') {
  window.location.href = hasTempoDir ? '/home' : '/home';
} else {
  Logger.log('config', { ...config });
  Sentry.init({
    dsn: 'https://b21ef2704414403696e2b88190200879@sentry.io/1834481',
    environment: sentryEnv,
    whitelistUrls: ['https://stg.kashiyama1927.jp', 'https://kashiyama1927.jp'],
  });
  ReactDOM.render(
    <ErrorBoundary>
      <NotFound />
    </ErrorBoundary>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.register();
  serviceWorker.unregister();

  // Warn when closing or reloading a page
  window.onbeforeunload = (e: any) => {
    if (config.isDev) {
      return;
    }
    e.returnValue = 'ページを離れようとしています。入力中の内容が消えますが、よろしいですか？';
  };
}
